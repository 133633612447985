.form-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}

form.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  margin-left: 50px;
  height: 30px;
  font-size: 15px;
}

.form-button {
  margin-left: 50px;
  background-color: #3F51B5;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
}